<div class="section section_5 section_pad section_5_pad_btm text_center" *ngIf="psp_data">
  <div class="page_container">
    <p class="page_sub_head bold_font page_sub_head_dark_color" data-aos="slide-up">
      {{psp_data['sub_head']}}
    </p>
    <p class="page_head section_page_head_gap extra_bold_font" data-aos="slide-up">
      {{psp_data['head']}}
    </p>
    <div class="section_5_section_container">
      <p class="page_sub_head page_sub_head_light_color" data-aos="slide-up">
        {{psp_data['text']}}
      </p>
      <div class="tab_view_wrapper" data-aos="slide-up">
        <app-psp-info-tabs [videos]="psp_data['videos']"></app-psp-info-tabs>
        <!-- <div class="psp_section egmat_tab_view" data-numberoftabs="3"
          data-contentconfig="bubbleChartsData">
          <div class="tab_view_container">
            <div class="tab_content_container">
              <ng-container *ngFor="let video of psp_data['videos'];let i = index;">
                <div class="tab_content" [ngClass]="(i == active_index) ? 'active_tab' : ''">
                    <app-iframe [url]="video['url']" [class]="'psp_wistia_video lozad wistia_embed'" [isIframeLoaded]="true" (loadEvent)="IframeLoad($event)"></app-iframe>
                </div>
              </ng-container>
            </div>
            <div class="tab_head_container">
              <ng-container *ngFor="let video of psp_data['videos']; let i = index;">
                <div (click)="change_tab(i)" class="tab_head" [ngClass]="(i == active_index) ? 'active_tab' : ''">
                  <span class="tab_text">
                    <span class="label">{{video['tab_label']}}</span>
                    <span class="duration_label">{{video['tab_duration_label']}}</span>
                    <span class="customer_name">{{video['customer_name']}}</span>
                  </span>
                </div>
              </ng-container>
              <div class="tab_select" [ngStyle]="{'left': left_tab_select+'%'}"></div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>
