
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleSigninBtnComponent } from '../common/google-signin-btn/google-signin-btn.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IframeComponent } from '../iframe/iframe.component';

@NgModule({
  declarations: [
    GoogleSigninBtnComponent,
    IframeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    GoogleSigninBtnComponent,
    IframeComponent
  ]
})
export class CommonSharedModule { }
