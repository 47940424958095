import { isPlatformBrowser } from '@angular/common';
import { UrlService } from './../Services/UrlService';
import { Component, Input, OnInit, OnChanges, Output, EventEmitter, PLATFORM_ID, Inject } from '@angular/core';
import lozad from 'lozad';

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.css']
})
export class IframeComponent implements OnInit,OnChanges {

  constructor(
    private urlService: UrlService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  @Input() url;
  @Input() class;
  @Input() isIframeLoaded = false;
  @Output() loadEvent = new EventEmitter();
  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const observer = lozad(); // lazy loads elements with default selector as '.lozad'
      observer.observe();
    }
  }
  ngOnChanges() : void{
    this.url = this.url ? this.urlService.updateVideoUrl(this.url) : '';
  }
  onLoadIframe(){
    if(this.isIframeLoaded && this.url){
      this.loadEvent.emit(this.url.changingThisBreaksApplicationSecurity.split('/').pop());
    }
  }
}
